<template>
  <div class="text-white" style="min-height:100vh;">
    <img style="position: absolute; top: -90px;z-index: 0;" width="100%" src="/assets/images/tasks_light.png" alt="img-top"/>
    <btnBack/>
    <div class="w-100 text-center" style="margin-top: -5px;z-index: 1000;position: relative;">
      <img width="160px" src="/assets/images/gift.png" alt="img-top"/>
    </div>
    <h2 class="text-center page-title" style="margin-top:4px;position: relative;">
      {{language.tasks}}
    </h2>

    <table class="table-tasks" style="position: relative;">
      <tr>
        <td>
          <img style="padding-top: 5px;float: left; margin-right: 5px; margin-top: -5px;" width="22px"
               src="/assets/images/king.png">{{language.firstBonus}}
          <br/>
          <img style="padding-top: 10px;float: left; clear: left; margin-right: 5px; margin-top: -10px;" width="20px"
               src="/assets/images/2D_smartoshi.png" alt="smartoshi">+500 000
        </td>
        <td><img style="padding-left: 10px;" width="36px" src="/assets/images/ok.png" alt="btn-ok"/></td>
      </tr>

      <tr v-for="(item, id) in tasks" :key="id">
        <td>
          <img style="padding-top: 5px;float: left; margin-right: 5px; margin-top: -5px;" width="20px"
               :src="'/assets/images/tasks/' + item['icon'] + '.png'" alt="icon">{{ item.title }}
          <br/>
          <img style="padding-top: 15px;float: left; clear: left; margin-right: 5px; margin-top: -15px;" width="20px"
               src="/assets/images/2D_smartoshi.png" alt="icon">+{{ new Intl.NumberFormat().format(item['reward']) }}
        </td>
        <td v-if="!myTasks[id]" @click="taskProcess(id, item['url'])">
          <img width="54px" src="/assets/images/go.png" alt="btn">
        </td>
        <td v-if="myTasks[id]">
          <span v-show="myTasks[id].status === false" ><img style="padding-left: 10px;" width="32px" src="/assets/images/tasks/spin.gif" alt="btn-spin"/></span>
          <span v-show="myTasks[id].status === true" ><img style="padding-left: 10px;" width="36px" src="/assets/images/ok.png" alt="btn-ok"/></span>
        </td>
      </tr>

    </table>

    <div class="container text-center mt-4">
      <div><a target="_blank" href="https://wiki.smartholdem.io/en/terms">Terms of Use</a> ✨ <a target="_blank" href="https://wiki.smartholdem.io/en/smartblocksgame">{{language.howPlay}}</a></div>
      <p class="mt-2"><a class="text-success" target="_blank" href="https://smartholdem.io">SmartHoldem.io</a></p>
    </div>

  </div>
</template>

<script>
import btnBack from "@/components/btnBack.vue";
import axios from "axios";
import {GAMEOPTIONS} from '@/game/scene/GameOption.js';

export default {
  components: {
    btnBack
  },
  data() {
    return {
      tasks: {},
      myTasks: {},
      tm: null,
      lang: 'en',
      language: {}
    }
  },
  async created() {
    this.lang = localStorage.getItem('lang') || 'en';
    if (this.lang === 'ru') {
      this.language = GAMEOPTIONS['ru'];
    } else {
      this.language = GAMEOPTIONS['en'];
    }
      let userId = localStorage.getItem('uid') || '260652640';
    try {
      const result = (await axios.get(GAMEOPTIONS.API + "/tasks/" + userId)).data;
      this.tasks = result['allTasks'];
      this.myTasks = result['userTasks'];
    } catch (e) {
      console.log('err: get tasks');
    }
  },
  methods: {
    async taskProcess(tId, url) {
      let userId = localStorage.getItem('uid') || '260652640';
      try {
        const result = (await axios.post(GAMEOPTIONS.API + "/task-process", {
          uid: userId,
          tid: tId,
        })).data;
        this.tasks = result['allTasks'];
        this.myTasks = result['userTasks'];
      } catch (e) {
        console.log('err: tasks connection')
      }
      window.open(url, '_blank');
      //console.log(tId, url, userId);
    }
  }
}
</script>


<style scoped lang="scss">


</style>
