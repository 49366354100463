<script>
import btnBack from "@/components/btnBack.vue";

export default {
  name: "PageNoConnect",
  components: {btnBack},
  data() {
    return {};
  },
  methods: {},
  mounted() {
  },
}
</script>

<template>
  <div class="page" style="overflow-x: hidden;">
    <btnBack/>

    <div class="container" style="margin-top:150px;">
      <h3>No Connection.. Please check internet connection!</h3>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
