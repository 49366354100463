<template>
  <div class="page" style="overflow-x: hidden;">
    <img style="position: absolute; top: -130px; left: -10%; z-index: 0;" width="120%" src="/assets/images/light.png" alt="img-top"/>
    <btnBack/>
    <div class="w-100 text-center" style="margin-top: 14px;position: relative;">
      <img width="212" src="/assets/images/top.png" alt="img-top"/>
    </div>

    <div class="mt-4 w-100" style="overflow-x:hidden; position:relative; float:left;">
      <div @click="defaultTab = 0" class="w-50"  :class="defaultTab === 0 ? 'tab-top-active'  : 'tab-top'">
        <div style="float:left;">
          <img style="margin-left: 10px;float: left; margin-right: 5px;padding-top: 4px;" width="18"
               src="/assets/images/moln.png"/>{{language.topM}}
        </div>
      </div>

      <div @click="defaultTab = 1" class="w-50" :class="defaultTab === 1 ? 'tab-top-active'  : 'tab-top'">
        <div style="float:left;">
          <img style="margin-left: 10px;float: left; margin-right: 5px;padding-top: 4px;" width="18"
               src="/assets/images/star.png"/>{{language.topWorld}}
        </div>
      </div>



    </div>


    <div v-show="defaultTab === 0" style="width: 100%;" class="text-white table-users-top">
      <table class="top-table w-100">
        <tr v-for="(item, index) in topMonth" :key="'m'+item.uid">
          <td class="text-center td1">
            <img v-show="index === 0" width="32px" src="/assets/images/N1.png" alt="top1">
            <img v-show="index === 1" width="32px" src="/assets/images/N2.png" alt="top2">
            <img v-show="index === 2" width="32px" src="/assets/images/N3.png" alt="top3">
            <span v-show="index > 2" style="font-size: 1.5em;">{{ index + 1 }}</span>
          </td>
          <td class="td2">
            <img v-if="item['isAvatar']" class="avatar" width="32px"
                 :src="'https://srv2048.smartholdem.io/avatars/' + item['uid'] + '.jpg'" alt="top3"/>
            <img v-else  width="24px" :src="'/assets/images/tg.png'" alt="top3"/>
          </td>
          <td :class="uid === item['uid'].toString() ? 'user-owner':'' ">{{ item['name'].substr(0, 28) }}<br/><span
              style="color: gold">{{ numberConvert(item['amount']) }}</span></td>
        </tr>
      </table>
    </div>

    <div v-show="defaultTab === 1" style="width: 100%;" class="text-white table-users-top">
      <table class="top-table w-100">
        <tr v-for="(item, index) in players">
          <td class="text-center td1">
            <img v-show="index === 0" width="32px" src="/assets/images/N1.png" alt="top1">
            <img v-show="index === 1" width="32px" src="/assets/images/N2.png" alt="top2">
            <img v-show="index === 2" width="32px" src="/assets/images/N3.png" alt="top3">
            <span v-show="index > 2" style="font-size: 1.5em;">{{ index + 1 }}</span>
          </td>
          <td class="td2">
            <img v-if="item['isAvatar']" class="avatar" width="32px"
                 :src="'https://srv2048.smartholdem.io/avatars/' + item['uid'] + '.jpg'" alt="top3"/>
            <img v-else  width="24px" :src="'/assets/images/tg.png'" alt="top3"/>
          </td>
          <td :class="uid === item['uid'].toString() ? 'user-owner':'' ">{{ item['name'].substr(0, 28) }}<br/><span
              style="color: gold">{{ numberConvert(item['score']) }}</span></td>
        </tr>
      </table>
    </div>




  </div>
</template>

<script>
import btnBack from "@/components/btnBack.vue";
import axios from 'axios';
import {GAMEOPTIONS} from '@/game/scene/GameOption.js';

export default {
  components: {
    btnBack
  },
  data() {
    return {
      defaultTab: 0,
      players: [],
      uid: 0,
      topMonth: {},
      lang: 'en',
      language: {}
    }
  },
  methods: {
    numberConvert(num) {
      let result = num;
      if (num > 1000 && num < 1000000) {
        result = (num / 1000).toFixed(2) + "K"

      }
      if (num > 1000000 && num < 1000000000) {
        result = (num / 1000000).toFixed(3) + 'M'
      }

      if (num > 1000000000 && num < 1000000000000) {
        result = (num / 1000000000).toFixed(3) + 'B'
      }

      if (num > 1000000000000) {
        result = (num / 1000000000000).toFixed(4) + 'T'
      }
      return result;
    }
  },
  async created() {
    this.lang = localStorage.getItem('lang') || 'en';
    if (this.lang === 'ru') {
      this.language = GAMEOPTIONS['ru'];
    } else {
      this.language = GAMEOPTIONS['en'];
    }

    this.uid = localStorage.getItem('uid') || '0'
    try {
      this.players = (await axios.get(GAMEOPTIONS.API + '/top')).data;
    } catch (e) {

    }

    try {
      this.topMonth = (await axios.get(GAMEOPTIONS.API + '/pay-month')).data['forPay'];
    } catch (e) {

    }

  }
}
</script>


<style scoped lang="scss">
.table-users-top {
  max-height: 62vh;
  overflow-y: auto;

}

.td1 {
  width: 54px;
}

.td2 {
  width: 48px;
  min-height: 32px;
}


.tab-top {
  padding: 8px;
  color: orange;font-size: 18px;float:left;
  background-color: #34322f9c;
}

.tab-top-active {
  padding: 8px;
  color: orange;font-size: 18px;float:left;border: none; background-color: #4f066c;
}

</style>
