<template>
  <img class="btn-back" @click="back()" src="/assets/images/btnBack.png" alt="back"/>
</template>

<script>
import eventBus from "@/plugins/event-bus";
export default {
  methods: {
    async back() {
      await eventBus.emit('page', '');
      await eventBus.emit('unPause', '');
    }
  }
}
</script>

<style scoped lang="scss">
  .btn-back {
    position: fixed;
    top: 45px;
  }
</style>
