<script>
import Game from '@/components/Game';
import eventBus from "@/plugins/event-bus";

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';

import PageTop from "@/pages/PageTop";
import PageWallet from "@/pages/PageWallet";
import PageRef from "@/pages/PageRef";
import PageTask from "@/pages/PageTask";
import PageNoConnect from "@/pages/PageNoConnect.vue";

export default {
  components: {
    PageNoConnect,
    Game,
    PageTop,
    PageWallet,
    PageRef,
    PageTask,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Navigation],
      page: 'swiper',
      swiperOff: false,
    }
  },
  methods: {
    goToGame() {
      this.page = '';
      localStorage.setItem('swiper_off', '1');
    }
  },
  async mounted() {

    eventBus.on('page', async (data) => {
      this.page = data;
    });
  },
  async created() {
    window.innerHeight < 700 ? this.$root.isDesktop = true : this.$root.isDesktop = false;
    this.swiperOff = localStorage.getItem('swiper_off') || false;
    if (this.swiperOff) {
      this.page = ''; // включить в production
    }
  }
}

</script>

<template>
  <div v-if="page !== ''" class="overlay-page">
       <div class="page-swiper">
      <div v-if="page === 'swiper'">
        <!-- Slider main container -->
        <swiper class="swiper"
                :pagination="{
      type: 'progressbar',
    }"
                :navigation="true"
                :modules="modules"
        >
          <!-- Additional required wrapper -->

          <!-- Slides -->
          <swiper-slide class="swiper-slide text-white"><img src="/assets/swiper_en/1.png" alt="swiper 1"/>
            <p class="text-center">
              <img class="sth-logo" src="/assets/images/sth_logo.png" alt="sth logo"/>
            </p>

          </swiper-slide>
          <swiper-slide class="swiper-slide text-white"><img src="/assets/swiper_en/2.png" alt="swiper 2"/>
            <p class="text-center">
              <img class="sth-logo" src="/assets/images/sth_logo.png" alt="sth logo"/>
            </p>
          </swiper-slide>
          <swiper-slide class="swiper-slide text-white"><img src="/assets/swiper_en/3.png" alt="swiper 3"/>
            <div @click="goToGame" class="text-center" style="position: absolute; bottom: 10%; left: 22%;">
              <img class="btn-next" src="/assets/images/en/btnPlay_next.png" alt="btn-next"/>
            </div>
          </swiper-slide>


        </swiper>
      </div>
    </div>
    <div v-if="page === 'top'" style="overflow-y: scroll;height: 100%;">
      <PageTop/>
    </div>
    <div v-if="page === 'wallet'">
      <PageWallet/>
    </div>
    <div v-if="page === 'ref'" style="overflow-y: scroll;height: 100%;">
      <PageRef/>
    </div>
    <div v-if="page === 'task'" style="overflow-y: scroll;height: 100%;">
      <PageTask/>
    </div>
    <div v-if="page === 'noConnect'" style="overflow-y: scroll;height: 100%;">
      <PageNoConnect/>
    </div>
  </div>
<!--
  <div v-if="page === ''">

  </div>
-->
  <Game/>


</template>

<style>


.swiper-button-prev, .swiper-button-next {
  color: #89f262;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #89f262;
}
.page-swiper {

}

.btn-next {
  width: 200px !important;
}

.swiper {
height: 100vh;
}

.swiper img {
  width: 100%;
  /*
  border-bottom: solid 1px #056560;
   */
}

.swiper .sth-logo {
  text-align: center;
  width:32%;
  border: none;
  margin-top: -12px;
}

.small {
  font-size: 0.9rem !important;
}

.inner-page {
  overflow-y: auto;
  overflow-x: hidden;
}

.m-10 {
  margin: 10px;
}

.w-50 {
  width: 50%;
}

.float-left {
  float: left;
}

.text-left {
  text-align: left;
}

.form-sth-wallet {
  font-size: 0.9rem;
  border: solid 1px #00b6ac;
  padding-left: 15px;
  padding-right: 25px;
  margin: 12px;
  color: #ffffff;
}

.form-sth-wallet-input {
  border: solid 1px #00b6ac;
  padding: 8px;
  background-color: #06091b;
  color: #00b6ac;
  width: 80%;
  border-radius: 0;
}

.form-sth-wallet-input:focus {
  border: solid 1px #097ff5;
  border-radius: 0;
}


.form-sth-wallet-key-input {

  border: solid 1px #d39c24;
  padding: 8px;
  background-color: #06091b;
  color: #d39c24;
  width: 80%;
  border-radius: 0;
}


.btn-green {
  text-decoration: none;
  padding: 5px 10px 5px 10px;
  font-size: 1.4rem;
  color: #fafafa;
  border: solid 1px #00b6ac;
  background: #056560;
}

.btn-orange {
  text-decoration: none;
  padding: 5px 10px 5px 10px;
  font-size: 1.4rem;
  color: #fafafa;
  border: solid 1px darkorange;
  background: coral;
}

.link-green {
  text-decoration: none;
  color: #00b6ac;
  font-size: 1.4rem;
}

body {
  background-color: #06091b;
  font-family: 'Poppins', sans-serif;
}

.page {
  border-top: solid 1px #056560;
  z-index:100000;
  position: absolute;
  color: #fafafa;
  font-family: 'Poppins', sans-serif;
  overflow-y: auto;
  min-height: 100vh;
  width:100%;
}

.user-owner {
  color: yellowgreen !important;
}

.avatar {
  border-radius: 50%;
  border: solid 2px #056560;
}



.top-table tr td {
  margin: 0;
  border: 0;
  color: #ffffff;
  padding: 5px;
}

.top-table tr td {
  border-bottom: solid 1px lightseagreen;
}

.page-title {
  color: #ffd334;
  text-align: center;
  font-weight: normal;
  width: 100%;
  border-bottom: solid 1px #00b6ac;
  padding-bottom: 5px;
  margin: 0;
}

/*
.text-center {
  text-align: center;
}

 */

.btn-back2 {
  margin-top: 32px;
  margin-left: 32px;
  height: 48px;
  z-index:100000;
  position:absolute;;
}

.btn-back {
  position: relative;
  z-index:100000;
  margin-top: 10px;
  margin-left: 10px;
  height: 64px;
}

.btn-back2:hover {
  cursor: pointer;
}

body {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.overlay-page {
  z-index:100000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: #06091b;
}
</style>
