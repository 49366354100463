<template>
  <div class="">
    <btnBack/>
    <!--
    <div style="z-index: 0; background-size: 94%; background-image: url('/assets/images/light.png');opacity: 0.2; background-repeat: no-repeat; background-position: center;position: absolute; width: 100%; height: 100vh;top:0;">
    </div>
    -->



      <div class="text-center text-white" style="position: relative; margin-top: 15px;">
        <img width="60%" src="/assets/images/stars.png" alt="stars"/>
        <h3 style="margin-top: 0px;">Win-Win</h3>
        <p v-show="!showFriends">{{language['inviteFriends']}}<br/>{{language['getMore']}}</p>
      </div>


      <div v-show="!showFriends" class="container-fluid">
        <div class="row">
          <div class="w-50 pl-4 pr-2">
            <div class="partner-block">
              {{language['friends']}}
              <div class="text-center w-100">
                <img class="ml-3" width="36px" src="/assets/images/hearts.png" alt="hearts"/>
                <span v-if="referrals">x {{ referrals.count }}</span>
                <span v-else>x 0</span>
              </div>
            </div>
          </div>

          <div class="w-50 pr-4">
            <div class="partner-block">
              {{language['earned']}}
              <div class="text-center w-100">
                <img class="ml-3" width="36px" src="/assets/images/2D_smartoshi.png" alt="2d smartoshi"/> <span
                  v-if="referrals">x {{ partnerAmount }}</span>
              </div>
            </div>
          </div>
        </div>


      </div>

      <div v-show="!showFriends" class="container text-center text-white mt-3">
        <p style="font-size: 14px; text-shadow: 1px 1px 0.12em #056560">🤑50,000,000 {{language['perFriend']}}!
        <br/>{{language['invitedReceive']}}
          <br/>500,000 {{language['coinGift']}}!🎁
        </p>
      </div>


      <div @click="ShareRef" class="container text-white mb-0" style="text-align: center; margin-top: -12px;">
        <div style="width: 85%;float:left;">
          <div
              style="box-shadow: 1px 1px 0.7em #056560 ;border: solid 1px #00b6ac; background-color: #00b6ac; margin:10px;font-size: 15px; border-radius: 6px;padding-top: 8px;padding-bottom: 8px;">
            {{language['invite']}}
          </div>
        </div>
        <div style="width: 15%;float:left;text-align: center;">
          <img style="width:100%; margin-top: 6px;" src="/assets/images/btnCopy.png" alt="btn"/>
        </div>
      </div>


      <div  class="container mb-3 pr-4" style="font-size: 12px; overflow: hidden;">

 <textarea class="ml-2 p-1 text-center" rows="4" v-show="!isCopied" readonly
           @click="copyText('https://t.me/sth2048bot/play?startapp=' + address)"
           style="width: 100%;background-color: #070b25; color: #00b6ac; font-size: 12px;  border: solid 1px #00b6ac">
        {{'https://t.me/sth2048bot/play?startapp=' + address}}
      </textarea>
          <div class="text-center pt-1 ml-2" v-show="isCopied" @click="copyText('https://t.me/sth2048bot/play?startapp=' + address)"
               style="clear: both; float:left; font-size: 1.2em; width: 99%;background-color: #070b25; color: #00b6ac; height: 32px; border: solid 1px #00b6ac">
            {{language['link_copy']}}
          </div>
        </div>

      <div class="container text-white">
        <p @click="showFriends = !showFriends">
          <img style="margin-left: 20px; float:left;margin-right: 6px; width: 20px;height: 24px;" src="/assets/images/playy.png"
               alt="light"/> {{language['my_friends']}}
          <span v-if="referrals">[{{ referrals.count }}]</span>
          <span v-else>[0]</span>
        </p>


        <div v-if="referrals">

          <table class="table-fiends">
            <tbody>
            <tr v-for="item in referrals['users']">
              <td class="text-right">{{ item['number'] }}</td>
              <td>{{ item['name'] }}</td>
              <td>+500K</td>
            </tr>
            </tbody>

          </table>


        </div>


      </div>
  </div>
</template>

<script>
import btnBack from "@/components/btnBack.vue";
import axios from 'axios';
import {GAMEOPTIONS} from '@/game/scene/GameOption.js';

export default {
  components: {
    btnBack
  },
  data() {
    return {
      showFriends: false,
      isCopied: false,
      address: 'SjE89tegMrT1N2V9h3EzETYF4Tkqm6Bdjc',
      referrals: null,
      partnerAmount: 0,
      gameConfig: GAMEOPTIONS,
      lang: 'en',
      language: {}
    }
  },
  async created() {
    this.address = localStorage.getItem('address');
    this.lang = localStorage.getItem('lang') || 'en';
    if (this.lang === 'ru') {
      this.language = GAMEOPTIONS['ru'];
    } else {
      this.language = GAMEOPTIONS['en'];
    }
  },
  async mounted() {
    try {
      this.referrals = (await axios.get(this.gameConfig.API + '/referrals/' + this.address)).data;
    } catch (e) {
      console.log('err no api access');
    }

    if (this.referrals) {
      if (this.referrals.amount > 10000) {
        this.partnerAmount = (this.referrals.amount / 1000).toFixed(0) + 'K'
      }

      if (this.referrals.amount > 10000) {
        this.partnerAmount = (this.referrals.amount / 1000000).toFixed(0) + 'M'
      }

      if (this.referrals.amount > 1000000000) {
        this.partnerAmount = (this.referrals.amount / 1000000000).toFixed(0) + 'Bil'
      }

      if (this.referrals.amount > 1000000000000) {
        this.partnerAmount = (this.referrals.amount / 1000000000000).toFixed(0) + 'T'
      }


    }
  },
  methods: {
    ShareRef() {
      window.open('https://t.me/share/url?url=t.me/sth2048bot/play?startapp=' + this.address, '_blank')
    },
    copyText(text) {
      this.isCopied = true;
      navigator.clipboard.writeText(text).then(function () {
        //console.log('Copying to clipboard was successful!');
      }, function (err) {
        console.error('Could not copy text: ', err);
      });
      setTimeout(() => this.isCopied = false, 1000);
    }
  }
}
</script>


<style scoped lang="scss">


.partner-block {
  color: #ffffff;
  position: relative;
  float: left;


  text-align: center;
  width: 100%;

  border: solid 1px #00b6ac;
  padding: 8px;
  min-height: 100%;
  height: 100%;

  background-color: #070b25;
}

.partner-block img {
  clear: left;
  margin-top: 10px;
  float: left;
  margin-right: 10px;
  margin-left: 5px;
}

.partner-block span {
  float: left;
  margin-top: 12px;
  font-size: 1.2rem;
}
</style>
