<template>
  <div class="text-white" style="min-height:100vh; ">
    <btnBack/>
    <div class="text-center inner-page" style="margin-top: 40px; position: relative;">

      <!--
      <div style="font-size: 18px">
        <img width="24" style="margin-bottom: 0px; border-radius: 50%; border: solid 1px darkorange;"
             :src="'https://srv2048.smartholdem.io/avatars/' + uid + '.jpg'" alt="top3"> {{ name }}
      </div>
      -->

      <!--
      <div class="mt-4 text-warning mb-1">
        Balance Smarts
      </div>
      -->
      <div class="container">
        <img width="36px" style="margin-bottom: 20px;" src="/assets/images/sth.png" alt="smartoshi"/> <span
          style="font-size: 42px;">{{ numberConvert(totalScore) }}</span>
      </div>


      <!--
      v-if="uid ==='260652640'"
      -->



      <div>
        <p style="color: #d39c24;" v-show="!privateKey" @click="showKey()"> >> {{language.pkey}} << </p>

        <div v-show="privateKey"
             style=" float:left; width: 100%; clear:both; text-align: center; font-size: 12px;margin-top:12px;">
          <label for="pKey" class="w-100">{{language.pkeySave}}</label>
          <textarea id="pKey" v-show="!isCopiedKey" @click="copyText(privateKey, 'key')"
                    style="margin: 5px; margin-top: 0px; min-height:90px; text-align: center;" readonly
                    class="form-sth-wallet-key-input" :value="privateKey">
            </textarea>
          <input v-show="isCopiedKey" style="margin: 10px;text-align: center;" readonly class="form-sth-wallet-input"
                 type="text" value="KEY IS COPIED" placeholder="PRIVATE KEY"/>
        </div>

        <div v-show="!privateKey && tFa" style="margin-bottom: 10px">
          <label for="uKeyNums" class="w-100" style="font-size: 0.8em;">{{language.digits}} <a target="_blank"
                                                                                         href="https://t.me/sth2048bot">https://t.me/sth2048bot</a></label>
          <input id="uKeyNums" inputmode="numeric" autocomplete="off" style="margin: 10px;text-align: center;"
                 class="form-sth-wallet-input" type="text" @change="getKey()"
                 @input="getKey()" v-model="uKey" :placeholder="language.otn"/>
        </div>

      </div>

      <div v-show="!tFa" style="padding:10px; margin-top: 5px;">
        <div class="w-100" v-if="userInfo">
          <a v-show="userInfo['paid'] > 0" style="color: #04f851; z-index: 100000;" target="_blank"
             :href="'https://explorer.smartholdem.io/#/wallets/' + address">{{language.paid}} {{ numberConvert(userInfo['paid']) }}
            Smartoshi</a>
        </div>

        <div class="w-100" v-if="userInfo">
          <span v-show="userInfo['toPay']" style="color: #09f5ca; z-index: 100000;">
            {{language.toPay}} {{ numberConvert(userInfo['toPay'] + userInfo['toPayRef']) }}</span>
        </div>
        <p class="small">
          {{language.smartholdem}}
          <!--
          <a target="_blank" href="https://wiki.smartholdem.io/en/smartblocksgame">SmartBlocks</a> is a game based on
          the SmartHoldem blockchain. The game is a decentralized application (DApp) that allows you to earn Smartoshi
          (Smarts) by playing. Smartoshi is a cryptocurrency that can be used to pay for services on the SmartHoldem
          blockchain.
          -->
        </p>
        <p class="small">
          {{language.smarts}} <a target="_blank"
                                                        href="https://smartholdem.io/news-details?id=33">[Smartoshi]</a>
        </p>
        <p class="small">
          1 STH = 100M Smarts
        </p>
        <p>
          <!--
          https://t.me/smartholdem_bot/wallet
          https://t.me/sthwlt_bot
          -->
          <button class="btn-green" @click="openUrl('https://t.me/smartholdem_bot/wallet?startapp=tg'+uid )">
            {{language.open_wallet}}
          </button>
        </p>
        <p>
          <a class="" target="_blank" href="https://wiki.smartholdem.io/en/smartblocksgame">{{language.guide}}</a>
        </p>
      </div>


      <div style="margin-top: -12px;">
        <h5 class="mb-2"><img width="22" src="/assets/images/sth_sq.png" alt="sth logo"/> {{language.y_address}}</h5>
        <div style=" float:left; width: 100%; clear:both; text-align: center; font-size: 12px;">
          <input v-show="!isCopied" @click="copyText(address, 'address')" style="margin: 10px;text-align: center;"
                 readonly class="form-sth-wallet-input" type="text" :value="address" placeholder="STH Address"/>
          <input v-show="isCopied" style="margin: 10px;text-align: center;" readonly class="form-sth-wallet-input"
                 type="text" value="ADDRESS IS COPIED" placeholder="STH Address"/>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import btnBack from "@/components/btnBack.vue";
import axios from "axios";
import {GAMEOPTIONS} from "@/game/scene/GameOption";

export default {
  components: {
    btnBack
  },
  data() {
    return {
      isAuth: false,
      tFa: false,
      uKey: '',
      privateKey: '',
      isCopied: false,
      isCopiedKey: false,
      name: '',
      uid: '',
      totalScore: 0,
      address: 'S...',
      userInfo: null,
      lang: 'en',
      language: {}
    }
  },
  methods: {
    async openUrl(url) {
      window.open(url, '_blank');
      //window.open(url, '_self');
    },
    async getUserInfo(uid) {
      //console.log('uid', uid);
      if (!uid) {
        return null;
      }
      let result = null;
      try {
        result = (await axios.get(GAMEOPTIONS.API + '/get-user/' + uid)).data;
        //console.log(result)
      } catch (e) {
        console.log('err connection')
      }
      return result;
    },
    async getKey() {
      const keyForValidate = this.uKey.trim();
      if (keyForValidate.length === 6) {
        const data = await axios.post(GAMEOPTIONS.API + '/user-pKey', {
          uid: this.uid,
          aKey: keyForValidate,
          address: this.address,
        });

        if (data.data['isAuth'] === true) {
          if (data.data['privateKey']) {
            this.privateKey = data.data['privateKey'];
          }
        }

      }
    },
    async showKey() {
      //const userId = '260652640';
      //this.tFa = true;
      let tg = window.Telegram.WebApp; //получаем объект webapp телеграма
      if (tg.initData) {
        this.uid = tg.initDataUnsafe.user.id.toString();
        //alert('uid: ' + tg.initDataUnsafe.user.id);
        try {
          const data = await axios.post(GAMEOPTIONS.API + '/user-aKey', {
            uid: tg.initDataUnsafe.user.id,
            address: this.address,
            data: tg.initDataUnsafe,
            initData: tg.initData,
          });

          if (data.data['isValidAuth']) {
            this.tFa = true;
          }
        } catch (e) {
          console.log('err connection')
        }
      }
    },
    numberConvert(num) {
      let result = num;
      if (num > 1000 && num < 1000000) {
        result = (num / 1000).toFixed(2) + "K"

      }
      if (num > 1000000 && num < 1000000000) {
        result = (num / 1000000).toFixed(3) + 'M'
      }

      if (num > 1000000000 && num < 1000000000000) {
        result = (num / 1000000000).toFixed(3) + 'B'
      }

      if (num > 1000000000000) {
        result = (num / 1000000000000).toFixed(4) + 'T'
      }
      return result;
    },
    copyText(text, type = 'address') {
      if (type === 'key') {
        this.isCopiedKey = true;
      } else {
        this.isCopied = true;
      }
      navigator.clipboard.writeText(text).then(function () {
        console.log('Copying to clipboard was successful!');
      }, function (err) {
        console.error('Could not copy text: ', err);
      });
      setTimeout(() => {
        this.isCopied = false;
        this.isCopiedKey = false;
        this.tFa = false;
      }, 1000);
    }
  },
  async created() {
    this.name = localStorage.getItem('name');
    this.uid = localStorage.getItem('uid');
    this.totalScore = localStorage.getItem('totalScore');
    this.address = localStorage.getItem('address');
    this.userInfo = await this.getUserInfo(this.uid);
    this.lang = localStorage.getItem('lang') || 'en';
    if (this.lang === 'ru') {
      this.language = GAMEOPTIONS['ru'];
    } else {
      this.language = GAMEOPTIONS['en'];
    }

  }
}
</script>


<style scoped lang="scss">

</style>
