<script setup>
import PhaserContainer from '@/components/PhaserContainer'
let tg = window.Telegram.WebApp;

//tg.ready();
if (tg.initData) {
  //console.log(tg)

  if (tg.initDataUnsafe.start_param) {
    localStorage.setItem('partner_id', tg.initDataUnsafe.start_param);
  } else {
    localStorage.setItem('partner_id', '');
  }

  tg.expand();
  tg.requestWriteAccess();
  tg.disableVerticalSwipes();
  tg.setBackgroundColor("#06091b");
  tg.MainButton.hide();
  tg.disableClosingConfirmation();


 // console.log(tg.platform);

  //tdesktop
  if (tg.platform !== 'tdesktop') {
    tg.requestFullscreen();
  } else {
    //console.log(tg.initDataUnsafe.user.id)
    if (tg.initDataUnsafe.user.id === 260652640) {
      //tg.showScanQrPopup();
      //alert(tg.platform);
    }

  }

  //
  //tg.sendData("Hello SmartHoldem Platform");

  try {
    localStorage.setItem('uid', tg.initDataUnsafe.user.id);
    localStorage.setItem('name', tg.initDataUnsafe.user.first_name)
  } catch(e) {

  }

} else {
  localStorage.setItem('uid', '260652640');
  localStorage.setItem('name', 'Guest1');
  localStorage.setItem('partner_id', '');
}




/**
 *
 *
 *  const opts = {
 *     reply_markup: {
 *       inline_keyboard: [
 *         [
 *           {
 *             text: 'Inline button name',
 *             web_app:{ url: 'Link to your mini app' }
 *           }
 *         ]
 *       ]
 *     }
 *   };
 *   bot.sendMessage(chatId, 'Some welcome message here', opts);
 */
</script>

<template>
<div>
  <Suspense>
    <PhaserContainer />
    <template #fallback>
      <div class="placeholder">
        Downloading ...
      </div>
    </template>
  </Suspense>

</div>


</template>

<style lang="scss" scoped>
#usercard {
  position:absolute;
  color: #fff;
  padding: 3px;
  bottom: 7%;
  left: 5%;
  width: 32px;
  height: 32px;

}

.tlg-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 2px rgba(211, 156, 36, 0.61);
}

.no-avatar {
  background-color: mediumseagreen;
  border-color: lightseagreen;
}

.placeholder {
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
  color: #89f262;
}
</style>
