export const GAMEOPTIONS = {
    version: "3.0.77",
    timeMerge: 200,
    tileSize: 100,
    tweenSpeed: 50,
    tileSpacing: 5,
    colum: 5,
    row: 8, // 8 - 1
    value: [2, 4, 8, 16, 32, 64, 128, 256, 512],
    // value:[2,4,8,16,32,64],
    colors: ['0x916BBF', '0xFFA500', '0xF14668', '0x77D970', '0x1597E5', '0x396EB0', '0xB8405E', '0x39A388', '0x325288'],
    // colors:['0x916BBF','0xFFA500','0xF14668','0x77D970','0x1597E5','0x396EB0'],
    START_DIAMOND: 150,
    BOT_NAME: "sth2048bot",
    API: "https://srv2048.smartholdem.io",
    //API: "http://localhost:3018",
    ru: {
        "open_wallet": "Открыть кошелек",
        "smartholdem": "SmartHoldem STH - это криптовалюта и платформа на основе блокчейна. SmartHoldem STH доступен для торговли на растущем числе бирж.",
        "smarts": "Наименьшая единица SmartHoldem STH - это Smarts",
        "y_address": "Ваш крипто-адрес",
        "guide": "Пошаговое руководство",
        "pkey": "ПОКАЗАТЬ ПРИВАТНЫЙ КЛЮЧ",
        "paid": "Оплачено",
        "toPay": "К оплате",
        "topWorld": "ТОП Мира",
        "topM": "ТОП Месяца",
        "tasks": "Задания",
        "firstBonus": "Приветственный бонус",
        "howPlay": "Как играть",
        "digits": "6 цифр из",
        "otn": "укажите одноразовый номер",
        "pkeySave": "Приватный ключ (храните его в секрете)",
        "no_refs": "Никого нет",
        "my_friends": "Приглашены друзья",
        "link_copy": "ССЫЛКА СКОПИРОВАНА",
        "invite": "ПРИГЛАСИТЕ ДРУЗЕЙ",
        "invitedReceive": "Приглашённые друзья получат",
        "coinGift": "монет в подарок",
        "perFriend": "монет за друга",
        "earned": "Заработано",
        "friends": "Друзья",
        "inviteFriends": "Пригласите друзей",
        "getMore": "и получите больше монет",
    },
    en: {
        "open_wallet": "Open wallet",
        "smartholdem": "SmartHoldem STH is a cryptocurrency and blockchain-based platform. SmartHoldem STH is available for trading on a growing number of exchanges.",
        "smarts": "The smallest SmartHoldem STH unit is Smarts",
        "y_address": "Your crypto-address",
        "guide": "Step-by-step guide",
        "pkey": "SHOW PRIVATE KEY",
        "pkeySave": "Private key (keep it a secret)",
        "paid": "Paid",
        "toPay": "To pay",
        "topWorld": "World's TOP",
        "topM": "Month's TOP",
        "tasks": "Tasks",
        "firstBonus": "First Game Bonus",
        "howPlay": "How to Play",
        "digits": "6 digits from",
        "otn": "enter one time number",
        "no_refs": "No friends",
        "my_friends": "My Friends list",
        "link_copy": "LINK IS COPIED",
        "invite": "INVITE FRIENDS",
        "invitedReceive": "Invited friend will receive",
        "coinGift": "coins as a gift",
        "perFriend": "coins per friend",
        "earned":"Earned",
        "friends": "Friends",
        "inviteFriends": "Invite friends",
        "getMore": "and get more coins",
    }
}
